@import 'ag-grid-community/dist/styles/ag-grid.css';
@import 'ag-grid-community/dist/styles/ag-theme-material.css';
@import '../../../assets/styles/variables/index.less';

@import 'antd/es/button/style/mixin.less';
@import 'antd/es/input/style/mixin.less';

@grid-padding: 10px;
@grid-row-height: 40px;
@grid-print-padding: 6px;

.ag-theme-material .ag-ltr {
  font-family: @font-family;
  font-size: @font-size-base;

  .ag-header-cell-label {
    font-family: inherit;
    font-size: inherit;
  }

  .ag-row-selected {
    border-color: @blue-1;
    background-color: @blue-1;
  }

  .ag-cell-focus {
    border-color: @blue-4;
  }

  .header-grid,
  .ag-header-cell-label,
  .ag-header-group-cell,
  .ag-row-group.ag-full-width-row,
  .ag-cell.ag-cell-not-inline-editing {
    line-height: @grid-row-height;
    // text-transform: uppercase;
  }

  .ag-header-cell,
  .ag-header-group-cell {
    padding: 0 0 0 @grid-padding;
    border: solid 1px #a1a1a15e;
    background-color: #f0f2f5;

    &.right {
      padding: 0;
      .ag-header-cell-label,
      .ag-header-group-cell-label {
        justify-content: flex-end;
        padding: 0 @grid-padding 0 0;
      }
    }

    &.center {
      padding: 0;
      .ag-header-cell-label,
      .ag-header-group-cell-label {
        justify-content: center;
      }
    }
  }

  .ag-paging-panel {
    background-color: #f0f2f5;
  }

  .ag-row-group.ag-full-width-row {
    padding: 0 0 0 @grid-padding;
  }

  .ag-cell {
    padding: 0 @grid-padding 0 @grid-padding;
    page-break-inside: avoid !important;
    border-right: solid 1px #f4f4f4 !important;

    &.ag-cell-highlight,
    &.ag-cell-highlight > * {
      border-color: @blue-6;
      background-color: @blue-6 !important;
      color: #fff;
    }

    &.ag-cell-not-inline-editing.right {
      text-align: right;
      padding: 0 @grid-padding 0 0;
    }

    &.ag-cell-inline-editing {
      height: @grid-row-height;
      padding: 0;
    }

    &.ag-cell-inline-editing.right .ag-input-field-input {
      text-align: right;
    }
  }

  .ag-cell-span {
    background-color: #fff;
  }

  .cell-wrap-text {
    white-space: normal !important;
  }

  .ag-details-row {
    padding: 0 0 0 @grid-row-height;
  }

  .ag-paging-panel {
    height: @grid-row-height;
  }

  .ag-checkbox-input-wrapper.ag-checked::after {
    color: @blue-6;
  }

  .ag-tabs-header.ag-menu-header {
    padding-bottom: 4px;

    .ag-tab.ag-tab-selected {
      border-bottom-color: @blue-6;

      &:only-child {
        display: none;
      }
    }
  }

  .ag-set-filter-item.ag-set-filter-select-all {
    line-height: @grid-row-height;
    padding: 0;
  }

  .ag-filter-apply-panel {
    display: flex;

    .ag-filter-apply-panel-button {
      text-transform: capitalize;
      .btn();
      &:first-child {
        .btn-default();
        margin: 0;
      }
      &:last-child {
        .btn-primary();
      }
    }
  }

  .ag-input-field-input.ag-text-field-input {
    .input();
  }

  .ag-header-cell-menu-button.ag-header-icon {
    opacity: 1 !important;
  }
}

.printing .ag-theme-material .ag-ltr {
  font-size: 12px;

  .ag-header-cell {
    padding: 0 0 0 @grid-print-padding;

    &.right {
      .ag-header-cell-label {
        padding: 0 @grid-print-padding 0 0;
      }
    }
  }

  .ag-row-group.ag-full-width-row {
    padding: 0 0 0 @grid-print-padding;
  }

  .ag-cell {
    border-left: solid 1px #e0e0e0;
    padding: 0 0 0 @grid-print-padding;
    &.ag-cell-not-inline-editing.right {
      padding: 0 @grid-print-padding 0 0;
    }
  }
}

.ag-theme-material .ag-ltr .ag-details-row {
  padding: 0 !important;
}

.ag-theme-material .ag-ltr .ag-row-level-1 .ag-row-group-leaf-indent {
  margin-left: unset !important;
}

.ag-theme-material .ag-ltr .ag-row-group-indent-1 {
  padding-left: 21px;
}

.ag-theme-material .ag-popup {
  position: absolute;
}
