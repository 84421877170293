.booking-online_header .ant-page-header-heading-left {
  flex: 1;
}
.booking-online_header .ant-page-header-heading-left .ant-page-header-heading-title {
  flex: 1;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-steps {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum';
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  font-size: 0;
  text-align: initial;
}
.ant-steps-item {
  position: relative;
  display: inline-block;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow: hidden;
  vertical-align: top;
}
.ant-steps-item-container {
  outline: none;
}
.ant-steps-item:last-child {
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
}
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail,
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-item-icon,
.ant-steps-item-content {
  display: inline-block;
  vertical-align: top;
}
.ant-steps-item-icon {
  width: 32px;
  height: 32px;
  margin: 0 8px 0 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 32px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  -webkit-transition: background-color 0.3s, border-color 0.3s;
  transition: background-color 0.3s, border-color 0.3s;
}
.ant-steps-item-icon .ant-steps-icon {
  position: relative;
  top: -1px;
  color: #1890ff;
  line-height: 1;
}
.ant-steps-item-tail {
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  padding: 0 10px;
}
.ant-steps-item-tail::after {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #f0f0f0;
  border-radius: 1px;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
  content: '';
}
.ant-steps-item-title {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px;
  line-height: 32px;
}
.ant-steps-item-title::after {
  position: absolute;
  top: 16px;
  left: 100%;
  display: block;
  width: 9999px;
  height: 1px;
  background: #f0f0f0;
  content: '';
}
.ant-steps-item-subtitle {
  display: inline;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px;
}
.ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #1890ff;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #1890ff;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #1890ff;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.85);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.65);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process .ant-steps-item-icon {
  background: #1890ff;
}
.ant-steps-item-process .ant-steps-item-icon .ant-steps-icon {
  color: #fff;
}
.ant-steps-item-process .ant-steps-item-title {
  font-weight: 500;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #1890ff;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #1890ff;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #1890ff;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.65);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #1890ff;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #1890ff;
}
.ant-steps-item-error .ant-steps-item-icon {
  background-color: #fff;
  border-color: #ff4d4f;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
  color: #ff4d4f;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #ff4d4f;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #ff4d4f;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #ff4d4f;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
  background: #ff4d4f;
}
.ant-steps-item-disabled {
  cursor: not-allowed;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] {
  cursor: pointer;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-description,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-icon .ant-steps-icon {
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
  color: #1890ff;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
  border-color: #1890ff;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
  color: #1890ff;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 16px;
  white-space: nowrap;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-right: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: none;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  max-width: 140px;
  white-space: normal;
}
.ant-steps-item-custom .ant-steps-item-icon {
  height: auto;
  background: none;
  border: 0;
}
.ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  top: 0px;
  left: 0.5px;
  width: 32px;
  height: 32px;
  font-size: 24px;
  line-height: 32px;
}
.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #1890ff;
}
.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
  width: auto;
  background: none;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 12px;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0;
}
.ant-steps-small .ant-steps-item-icon {
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
}
.ant-steps-small .ant-steps-item-title {
  padding-right: 12px;
  font-size: 14px;
  line-height: 24px;
}
.ant-steps-small .ant-steps-item-title::after {
  top: 12px;
}
.ant-steps-small .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-steps-small .ant-steps-item-tail {
  top: 8px;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  background: none;
  border: 0;
  border-radius: 0;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 24px;
  -webkit-transform: none;
          transform: none;
}
.ant-steps-vertical {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.ant-steps-vertical .ant-steps-item {
  display: block;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  overflow: visible;
}
.ant-steps-vertical .ant-steps-item-icon {
  float: left;
  margin-right: 16px;
}
.ant-steps-vertical .ant-steps-item-content {
  display: block;
  min-height: 48px;
  overflow: hidden;
}
.ant-steps-vertical .ant-steps-item-title {
  line-height: 32px;
}
.ant-steps-vertical .ant-steps-item-description {
  padding-bottom: 12px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 16px;
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
  width: 1px;
  height: 100%;
}
.ant-steps-vertical > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
  display: block;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 12px;
  padding: 30px 0 6px;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
  line-height: 24px;
}
.ant-steps-rtl.ant-steps-vertical .ant-steps-item-icon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-steps-rtl.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: 16px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  right: 12px;
  left: auto;
}
@media (max-width: 480px) {
  .ant-steps-horizontal.ant-steps-label-horizontal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
    display: block;
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    overflow: visible;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
    float: left;
    margin-right: 16px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
    display: block;
    min-height: 48px;
    overflow: hidden;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-title {
    line-height: 32px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-description {
    padding-bottom: 12px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    position: absolute;
    top: 0;
    left: 16px;
    width: 1px;
    height: 100%;
    padding: 38px 0 6px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
    width: 1px;
    height: 100%;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
    display: block;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    display: none;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
    position: absolute;
    top: 0;
    left: 12px;
    padding: 30px 0 6px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
    line-height: 24px;
  }
  .ant-steps-rtl.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
    float: right;
    margin-right: 0;
    margin-left: 16px;
  }
  .ant-steps-rtl.ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    right: 16px;
    left: auto;
  }
  .ant-steps-rtl.ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
    right: 12px;
    left: auto;
  }
}
.ant-steps-label-vertical .ant-steps-item {
  overflow: visible;
}
.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 58px;
  padding: 3.5px 24px;
}
.ant-steps-label-vertical .ant-steps-item-content {
  display: block;
  width: 116px;
  margin-top: 8px;
  text-align: center;
}
.ant-steps-label-vertical .ant-steps-item-icon {
  display: inline-block;
  margin-left: 42px;
}
.ant-steps-label-vertical .ant-steps-item-title {
  padding-right: 0;
  padding-left: 0;
}
.ant-steps-label-vertical .ant-steps-item-title::after {
  display: none;
}
.ant-steps-label-vertical .ant-steps-item-subtitle {
  display: block;
  margin-bottom: 4px;
  margin-left: 0;
  line-height: 1.5715;
}
.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
  margin-left: 46px;
}
.ant-steps-dot .ant-steps-item-title,
.ant-steps-dot.ant-steps-small .ant-steps-item-title {
  line-height: 1.5715;
}
.ant-steps-dot .ant-steps-item-tail,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  top: 2px;
  width: 100%;
  margin: 0 0 0 70px;
  padding: 0;
}
.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  width: calc(100% - 20px);
  height: 3px;
  margin-left: 12px;
}
.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 2px;
}
.ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  width: 8px;
  height: 8px;
  margin-left: 67px;
  padding-right: 0;
  line-height: 8px;
  background: transparent;
  border: 0;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  /* expand hover area */
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  position: absolute;
  top: -12px;
  left: -26px;
  width: 60px;
  height: 32px;
  background: rgba(0, 0, 0, 0.001);
  content: '';
}
.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
  position: relative;
  top: -1px;
  width: 10px;
  height: 10px;
  line-height: 10px;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-top: 8px;
  margin-left: 0;
  background: none;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 2px;
  left: -9px;
  margin: 0;
  padding: 22px 0 4px;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
  left: -2px;
}
.ant-steps-navigation {
  padding-top: 12px;
}
.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-left: -12px;
}
.ant-steps-navigation .ant-steps-item {
  overflow: visible;
  text-align: center;
}
.ant-steps-navigation .ant-steps-item-container {
  display: inline-block;
  height: 100%;
  margin-left: -16px;
  padding-bottom: 12px;
  text-align: left;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-content {
  max-width: auto;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  max-width: 100%;
  padding-right: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button'] {
  cursor: pointer;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button']:hover {
  opacity: 0.85;
}
.ant-steps-navigation .ant-steps-item:last-child {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.ant-steps-navigation .ant-steps-item:last-child::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item::after {
  position: absolute;
  top: 50%;
  left: 100%;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -14px;
  margin-left: -2px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: none;
  border-left: none;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  content: '';
}
.ant-steps-navigation .ant-steps-item::before {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: inline-block;
  width: 0;
  height: 2px;
  background-color: #1890ff;
  -webkit-transition: width 0.3s, left 0.3s;
  transition: width 0.3s, left 0.3s;
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
  content: '';
}
.ant-steps-navigation .ant-steps-item.ant-steps-item-active::before {
  left: 0;
  width: 100%;
}
@media (max-width: 480px) {
  .ant-steps-navigation > .ant-steps-item {
    margin-right: 0 !important;
  }
  .ant-steps-navigation > .ant-steps-item::before {
    display: none;
  }
  .ant-steps-navigation > .ant-steps-item.ant-steps-item-active::before {
    top: 0;
    right: 0;
    left: unset;
    display: block;
    width: 3px;
    height: calc(100% - 24px);
  }
  .ant-steps-navigation > .ant-steps-item::after {
    position: relative;
    top: -2px;
    left: 50%;
    display: block;
    width: 8px;
    height: 8px;
    margin-bottom: 8px;
    text-align: center;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  .ant-steps-navigation > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    visibility: hidden;
  }
}
.ant-steps-rtl {
  direction: rtl;
}
.ant-steps.ant-steps-rtl .ant-steps-item-icon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-steps-rtl .ant-steps-item-tail {
  right: 0;
  left: auto;
}
.ant-steps-rtl .ant-steps-item-title {
  padding-right: 0;
  padding-left: 16px;
}
.ant-steps-rtl .ant-steps-item-title::after {
  right: 100%;
  left: auto;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 0;
  margin-left: 16px;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child {
  margin-left: 0;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  right: 0.5px;
  left: auto;
}
.ant-steps-rtl.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-right: -12px;
  margin-left: 0;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container {
  margin-right: -16px;
  margin-left: 0;
  text-align: right;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item::after {
  right: 100%;
  left: auto;
  margin-right: -2px;
  margin-left: 0;
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
}
.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 0;
  margin-left: 12px;
}
.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child {
  margin-left: 0;
}
.ant-steps-rtl.ant-steps-small .ant-steps-item-title {
  padding-right: 0;
  padding-left: 12px;
}
.ant-steps-rtl.ant-steps-label-vertical .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  margin: 0 70px 0 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  margin-right: 12px;
  margin-left: 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 2px;
  left: auto;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  margin-right: 67px;
  margin-left: 0;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  /* expand hover area */
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  float: right;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  right: -26px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-right: 0;
  margin-left: 16px;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: -9px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 0;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
  right: -2px;
  left: auto;
}
.ant-steps:not(.ant-steps-dot):not(.ant-steps-navigation):not(.ant-steps-vertical) .ant-steps-item {
  padding-top: 4px;
}
.ant-steps:not(.ant-steps-dot):not(.ant-steps-navigation) .ant-steps-item .ant-steps-item-icon {
  position: relative;
}
.ant-steps:not(.ant-steps-dot):not(.ant-steps-navigation) .ant-steps-item .ant-steps-item-icon .ant-progress {
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
}

@import 'ag-grid-community/dist/styles/ag-grid.css';
@import 'ag-grid-community/dist/styles/ag-theme-material.css';
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ag-theme-material .ag-ltr {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 14px;
}
.ag-theme-material .ag-ltr .ag-header-cell-label {
  font-family: inherit;
  font-size: inherit;
}
.ag-theme-material .ag-ltr .ag-row-selected {
  border-color: #e6f7ff;
  background-color: #e6f7ff;
}
.ag-theme-material .ag-ltr .ag-cell-focus {
  border-color: #69c0ff;
}
.ag-theme-material .ag-ltr .header-grid,
.ag-theme-material .ag-ltr .ag-header-cell-label,
.ag-theme-material .ag-ltr .ag-header-group-cell,
.ag-theme-material .ag-ltr .ag-row-group.ag-full-width-row,
.ag-theme-material .ag-ltr .ag-cell.ag-cell-not-inline-editing {
  line-height: 40px;
}
.ag-theme-material .ag-ltr .ag-header-cell,
.ag-theme-material .ag-ltr .ag-header-group-cell {
  padding: 0 0 0 10px;
  border: solid 1px #a1a1a15e;
  background-color: #f0f2f5;
}
.ag-theme-material .ag-ltr .ag-header-cell.right,
.ag-theme-material .ag-ltr .ag-header-group-cell.right {
  padding: 0;
}
.ag-theme-material .ag-ltr .ag-header-cell.right .ag-header-cell-label,
.ag-theme-material .ag-ltr .ag-header-group-cell.right .ag-header-cell-label,
.ag-theme-material .ag-ltr .ag-header-cell.right .ag-header-group-cell-label,
.ag-theme-material .ag-ltr .ag-header-group-cell.right .ag-header-group-cell-label {
  justify-content: flex-end;
  padding: 0 10px 0 0;
}
.ag-theme-material .ag-ltr .ag-header-cell.center,
.ag-theme-material .ag-ltr .ag-header-group-cell.center {
  padding: 0;
}
.ag-theme-material .ag-ltr .ag-header-cell.center .ag-header-cell-label,
.ag-theme-material .ag-ltr .ag-header-group-cell.center .ag-header-cell-label,
.ag-theme-material .ag-ltr .ag-header-cell.center .ag-header-group-cell-label,
.ag-theme-material .ag-ltr .ag-header-group-cell.center .ag-header-group-cell-label {
  justify-content: center;
}
.ag-theme-material .ag-ltr .ag-paging-panel {
  background-color: #f0f2f5;
}
.ag-theme-material .ag-ltr .ag-row-group.ag-full-width-row {
  padding: 0 0 0 10px;
}
.ag-theme-material .ag-ltr .ag-cell {
  padding: 0 10px 0 10px;
  page-break-inside: avoid !important;
  border-right: solid 1px #f4f4f4 !important;
}
.ag-theme-material .ag-ltr .ag-cell.ag-cell-highlight,
.ag-theme-material .ag-ltr .ag-cell.ag-cell-highlight > * {
  border-color: #1890ff;
  background-color: #1890ff !important;
  color: #fff;
}
.ag-theme-material .ag-ltr .ag-cell.ag-cell-not-inline-editing.right {
  text-align: right;
  padding: 0 10px 0 0;
}
.ag-theme-material .ag-ltr .ag-cell.ag-cell-inline-editing {
  height: 40px;
  padding: 0;
}
.ag-theme-material .ag-ltr .ag-cell.ag-cell-inline-editing.right .ag-input-field-input {
  text-align: right;
}
.ag-theme-material .ag-ltr .ag-cell-span {
  background-color: #fff;
}
.ag-theme-material .ag-ltr .cell-wrap-text {
  white-space: normal !important;
}
.ag-theme-material .ag-ltr .ag-details-row {
  padding: 0 0 0 40px;
}
.ag-theme-material .ag-ltr .ag-paging-panel {
  height: 40px;
}
.ag-theme-material .ag-ltr .ag-checkbox-input-wrapper.ag-checked::after {
  color: #1890ff;
}
.ag-theme-material .ag-ltr .ag-tabs-header.ag-menu-header {
  padding-bottom: 4px;
}
.ag-theme-material .ag-ltr .ag-tabs-header.ag-menu-header .ag-tab.ag-tab-selected {
  border-bottom-color: #1890ff;
}
.ag-theme-material .ag-ltr .ag-tabs-header.ag-menu-header .ag-tab.ag-tab-selected:only-child {
  display: none;
}
.ag-theme-material .ag-ltr .ag-set-filter-item.ag-set-filter-select-all {
  line-height: 40px;
  padding: 0;
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel {
  display: flex;
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button {
  text-transform: capitalize;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button > .anticon {
  line-height: 1;
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button,
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:active,
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:focus {
  outline: 0;
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:not([disabled]):hover {
  text-decoration: none;
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button[disabled] {
  cursor: not-allowed;
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button[disabled] > * {
  pointer-events: none;
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button-lg {
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border-radius: 2px;
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button-sm {
  height: 24px;
  padding: 0px 7px;
  font-size: 14px;
  border-radius: 2px;
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:first-child {
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-color: #d9d9d9;
  margin: 0;
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:first-child > a:only-child {
  color: currentColor;
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:first-child > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:first-child:hover,
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:first-child:focus {
  color: #40a9ff;
  background: #fff;
  border-color: #40a9ff;
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:first-child:hover > a:only-child,
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:first-child:focus > a:only-child {
  color: currentColor;
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:first-child:hover > a:only-child::after,
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:first-child:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:first-child:active {
  color: #096dd9;
  background: #fff;
  border-color: #096dd9;
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:first-child:active > a:only-child {
  color: currentColor;
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:first-child:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:first-child[disabled],
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:first-child[disabled]:hover,
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:first-child[disabled]:focus,
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:first-child[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:first-child[disabled] > a:only-child,
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:first-child[disabled]:hover > a:only-child,
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:first-child[disabled]:focus > a:only-child,
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:first-child[disabled]:active > a:only-child {
  color: currentColor;
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:first-child[disabled] > a:only-child::after,
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:first-child[disabled]:hover > a:only-child::after,
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:first-child[disabled]:focus > a:only-child::after,
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:first-child[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:first-child:hover,
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:first-child:focus,
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:first-child:active {
  text-decoration: none;
  background: #fff;
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:last-child {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:last-child > a:only-child {
  color: currentColor;
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:last-child > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:last-child:hover,
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:last-child:focus {
  color: #fff;
  background: #40a9ff;
  border-color: #40a9ff;
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:last-child:hover > a:only-child,
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:last-child:focus > a:only-child {
  color: currentColor;
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:last-child:hover > a:only-child::after,
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:last-child:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:last-child:active {
  color: #fff;
  background: #096dd9;
  border-color: #096dd9;
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:last-child:active > a:only-child {
  color: currentColor;
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:last-child:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:last-child[disabled],
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:last-child[disabled]:hover,
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:last-child[disabled]:focus,
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:last-child[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:last-child[disabled] > a:only-child,
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:last-child[disabled]:hover > a:only-child,
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:last-child[disabled]:focus > a:only-child,
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:last-child[disabled]:active > a:only-child {
  color: currentColor;
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:last-child[disabled] > a:only-child::after,
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:last-child[disabled]:hover > a:only-child::after,
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:last-child[disabled]:focus > a:only-child::after,
.ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:last-child[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ag-theme-material .ag-ltr .ag-input-field-input.ag-text-field-input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}
.ag-theme-material .ag-ltr .ag-input-field-input.ag-text-field-input::-moz-placeholder {
  opacity: 1;
}
.ag-theme-material .ag-ltr .ag-input-field-input.ag-text-field-input::placeholder {
  color: #bfbfbf;
}
.ag-theme-material .ag-ltr .ag-input-field-input.ag-text-field-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ag-theme-material .ag-ltr .ag-input-field-input.ag-text-field-input:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ag-theme-material .ag-ltr .ag-input-field-input.ag-text-field-input:focus,
.ag-theme-material .ag-ltr .ag-input-field-input.ag-text-field-input-focused {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ag-theme-material .ag-ltr .ag-input-field-input.ag-text-field-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ag-theme-material .ag-ltr .ag-input-field-input.ag-text-field-input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ag-theme-material .ag-ltr .ag-input-field-input.ag-text-field-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ag-theme-material .ag-ltr .ag-input-field-input.ag-text-field-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ag-theme-material .ag-ltr .ag-input-field-input.ag-text-field-input-borderless,
.ag-theme-material .ag-ltr .ag-input-field-input.ag-text-field-input-borderless:hover,
.ag-theme-material .ag-ltr .ag-input-field-input.ag-text-field-input-borderless:focus,
.ag-theme-material .ag-ltr .ag-input-field-input.ag-text-field-input-borderless-focused,
.ag-theme-material .ag-ltr .ag-input-field-input.ag-text-field-input-borderless-disabled,
.ag-theme-material .ag-ltr .ag-input-field-input.ag-text-field-input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ag-theme-material .ag-ltr .ag-input-field-input.ag-text-field-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ag-theme-material .ag-ltr .ag-input-field-input.ag-text-field-input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ag-theme-material .ag-ltr .ag-input-field-input.ag-text-field-input-sm {
  padding: 0px 7px;
}
.ag-theme-material .ag-ltr .ag-header-cell-menu-button.ag-header-icon {
  opacity: 1 !important;
}
.printing .ag-theme-material .ag-ltr {
  font-size: 12px;
}
.printing .ag-theme-material .ag-ltr .ag-header-cell {
  padding: 0 0 0 6px;
}
.printing .ag-theme-material .ag-ltr .ag-header-cell.right .ag-header-cell-label {
  padding: 0 6px 0 0;
}
.printing .ag-theme-material .ag-ltr .ag-row-group.ag-full-width-row {
  padding: 0 0 0 6px;
}
.printing .ag-theme-material .ag-ltr .ag-cell {
  border-left: solid 1px #e0e0e0;
  padding: 0 0 0 6px;
}
.printing .ag-theme-material .ag-ltr .ag-cell.ag-cell-not-inline-editing.right {
  padding: 0 6px 0 0;
}
.ag-theme-material .ag-ltr .ag-details-row {
  padding: 0 !important;
}
.ag-theme-material .ag-ltr .ag-row-level-1 .ag-row-group-leaf-indent {
  margin-left: unset !important;
}
.ag-theme-material .ag-ltr .ag-row-group-indent-1 {
  padding-left: 21px;
}
.ag-theme-material .ag-popup {
  position: absolute;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-statistic {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum';
}
.ant-statistic-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-statistic-content {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
.ant-statistic-content-value {
  display: inline-block;
  direction: ltr;
}
.ant-statistic-content-value-decimal {
  font-size: 16px;
}
.ant-statistic-content-prefix,
.ant-statistic-content-suffix {
  display: inline-block;
}
.ant-statistic-content-prefix {
  margin-right: 4px;
}
.ant-statistic-content-suffix {
  margin-left: 4px;
  font-size: 16px;
}
.ant-statistic-rtl {
  direction: rtl;
}
.ant-statistic-rtl .ant-statistic-content-prefix {
  margin-right: 0;
  margin-left: 4px;
}
.ant-statistic-rtl .ant-statistic-content-suffix {
  margin-right: 4px;
  margin-left: 0;
}

/*# sourceMappingURL=BookingOnlineScreen.60b97968.css.map */
